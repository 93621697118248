<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      <ul>
        <li>베팅금액</li>
        <li>{{ item["bet"] }}</li>
      </ul>
      <ul>
        <li>당첨금액</li>
        <li>{{ item["award"] }}</li>
      </ul>
      <ul>
        <li>베팅시작시간</li>
        <li>시작시간:{{ item["time"].start }},&nbsp;{{ $t('searchArea.endDate') }}:{{ item["time"].settle }}</li>
      </ul>
      <ul>
        <li>{{ $t('table.head.wl') }}</li>
        <li>{{ item["winlose"] }}</li>
      </ul>
      <ul>
        <li>라운드ID</li>
        <li>{{ item["roundId"] }}</li>
      </ul>
      <ul>
        <li>테이블번호</li>
        <li>{{ item["tableId"] }}</li>
      </ul>
      <ul>
        <li>오더ID</li>
        <li>{{ item["orderId"] }}</li>
      </ul>
      <ul>
        <li>플레이타입</li>
        <li>{{ item["playType"] }}</li>
      </ul>
    </div>
    <p class="dataname">결과</p>
    <div class="detailData">
       <ul>
         <li>페어여부</li>
         <li>{{ item["result"].pair }}</li>
       </ul>
       <ul>
         <li>뱅커</li>
         <li>{{ item["result"].banker }}</li>
       </ul>
       <ul>
         <li>플레이어</li>
         <li>{{ item["result"].player }}</li>
       </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode9',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
